import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from '~~/stores/AuthStore';

const { checkStatusCode } = useCheckRequestStatusCode();

export const useProfileStore = defineStore('profile', {
  state: () => ({
    personalInformation: null,
    countriesList: null,
    loading: false,
    serverErrors: {},
    responseData: null,
  }),
  getters: {
    fullName(state) {
      return (
        state.personalInformation.firstName +
        ' ' +
        state.personalInformation.lastName
      );
    },
  },
  actions: {
    async getPersonalInformation() {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/profile`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );
      this.responseData = null;
      this.loading = false;
      if (!error.value) {
        this.personalInformation = response.value.data;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async updateProfile(payload) {
      const authStore = useAuthStore();
      this.responseData = null;
      this.serverErrors = {};
      const formData = new FormData();
      formData.append('_method', 'patch');
      for (const key in payload) {
        if (key == 'gender') {
          formData.append(
            key,
            payload[key] === 'MALE' || payload[key] === 'ذكر'
              ? 'MALE'
              : 'FEMALE'
          );
        } else if (key == 'birthDate') {
          if (payload.birthDate !== this.personalInformation.birthDate) {
            formData.append(key, payload[key]);
          }
        } else {
          if (this.personalInformation[key] !== payload[key]) {
            formData.append(key, payload[key]);
          }
        }
      }
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/profile`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      if (!error.value) {
        this.responseData = response.value;
        setTimeout(() => {
          this.getPersonalInformation();
        }, 3000);
        return response.value;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async getCountries() {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/countries?nationality_list=true`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.countriesList = response.value.data;
      } else {
        checkStatusCode(error.value.data.status_code);
      }
    },
    async sendVerificationPhoneCode(payload) {
      const authStore = useAuthStore();
      this.serverErrors = {};
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${
          useRuntimeConfig().public.baseUrl
        }/profile/send-verification-phone-code?phone=${payload}`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        return response.value;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async verifyPhone(payload) {
      const authStore = useAuthStore();
      this.serverErrors = {};
      this.loading = true;
      const formData = new FormData();

      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/profile/verify-phone`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        return response.value;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
  },
});
